<!-- 
  This template creates a responsive Quote section with a child component called 'Quote-Artist'
  that display the information of the Artist (John), as well as some information about him.
  The content is displayed differently depending on the device It's viewed on
 -->
<template>
  <section>
    <div class="quote-container">
      <!-- Quote child component rendered if It's the Desktop version -->
      <Quote_Artist v-show="!mobile" />

      <div class="artist-content-container">
        <!-- 
            'getQuoteData' retrieves specific pieces of data from the 
            homeData prop (which comes from prismic) 
          -->
        <h2 class="artist-content-container-title">
          {{ getQuoteData("artisttitle") }}
        </h2>

        <!-- If 'mobile' data property is triggered, Quote child component 
            is rendered based on the mobile version -->
        <Quote_Artist v-show="mobile" />

        <p class="artist-content-container-description">
          {{ getQuoteData("text1") }}
        </p>
        <p class="artist-content-container-quote">
          {{ getQuoteData("text2") }}
        </p>
        <p class="artist-content-container-name">
          {{ getQuoteData("artistname") }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import Quote_Artist from "../Quote/Quote-Artist.vue";

export default {
  name: "Quote",
  /* The Quote component receives a prop called homeData,
   which is an object that contains data needed for the text. */
  props: ["homeData"],
  /**
   *@description
      Register the Quote_Artist component
    @summary
      The component uses a child component called Quote_Artist, 
      that shows the artist information, which is conditionally 
      rendered based on the mobile data property.
   */
  components: {
    Quote_Artist,
  },
  data() {
    return {
      mobile: null,
      windowWidth: null,
    };
  },
  /**@event
   * Event listener for the resize event on the window object, which triggers the checkScreen method
   */
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    /**@method
     * This method updates the mobile property based on the screen width,
     * and sets it to true if the screen width is less than or equal to 1300 pixels.
     */
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.mobile = this.windowWidth <= 1300;
    },
    /**@method
     * The 'getQuoteData' method is used to retrieve specific pieces of data from the homeData object.
     * @summary
     * It takes a key as an argument and returns the text associated with that key.
     * If the text is not available, it returns an empty string.
     * */
    getQuoteData(key) {
      return this.homeData?.data?.artist[0]?.[key]?.[0]?.text || "";
    },
  },
};
</script>
