<!-- 
  This template creates a responsive About Indige section that displays different content depending on 
  the device it's viewed on. 
 -->
<template>
  <div>
    <section class="about-container" id="about">
      <!-- AboutIndige child component rendered if It's the Desktop version -->
      <AboutIndigeImage v-show="!mobile" />

      <div class="about-container-content">
        <!-- 'getAboutIndigeData' retrieves specific pieces of data from the homeData prop (which comes from prismic) -->
        <h2>
          {{ getAboutIndigeData("title", 0) }}
        </h2>

        <p class="headline-tag">
          {{ getAboutIndigeData("quote", 0) }}
        </p>

        <!-- If 'mobile' data property is triggered, AboutIndige child component 
            is rendered based on the mobile version -->
        <AboutIndigeImage v-show="mobile" />

        <p>
          {{ getAboutIndigeData("abouttext", 0) }}
          <br /><br />
          {{ getAboutIndigeData("abouttext", 1) }}
        </p>

        <!-- 
          The div tag "about-buttons" holds a button with a white background that links 
          to the Indige Discord server. The text on the button is retrieved using the "getAboutIndigeData" method.
         -->
        <div class="about-buttons">
          <a
            class="white-button"
            href="https://discord.com/invite/rUkaqDNBTh"
            target="_blank"
            >{{ getAboutIndigeData("button1", 0) }}
            <img src="@/assets/img/arrow-down.png" alt="" />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AboutIndigeImage from "../AboutIndige/AboutIndige-Image.vue";

export default {
  name: "AboutIndige",

  /* The AboutIndige component receives a prop called homeData,
   which is an object that contains data needed for the text. */
  props: ["homeData"],

  /**
   *@description
      Register the AboutIndigeImage component
    @summary
      The component uses a child component called AboutIndigeImage,
      which is conditionally rendered based on the mobile data property.
   */
  components: {
    AboutIndigeImage,
  },
  data() {
    return {
      mobile: null,
      windowWidth: null,
    };
  },
  /**@event
   * Event listener for the resize event on the window object, which triggers the checkScreen method
   */
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    /**@method
     * The 'getAboutIndigeData' method is used to retrieve specific pieces of data from the homeData object.
     * @summary
     * It takes a key and an index as arguments and returns the text associated with that key and index.
     * If the text is not available, it returns an empty string.
     * */
    getAboutIndigeData(key, index) {
      return this.homeData?.data?.homeabout[0]?.[key]?.[index]?.text || "";
    },

    /**@method
     * This method updates the mobile property based on the screen width,
     * and sets it to true if the screen width is less than or equal to 960 pixels.
     */
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.mobile = this.windowWidth <= 960;
    },
  },
};
</script>
