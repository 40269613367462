<!-- 
    This code defines a Vue component called "OurVideo" which 
    displays a video with a play/pause button.
 -->
<template>
  <div class="video-container">
    <video
      class="home-video"
      preload="auto"
      poster="@/assets/img/johnthumbnail.png"
      alt="indige"
      src="https://res.cloudinary.com/risidio/video/upload/v1650442210/indige-test/indige___video_3_720p_2_ylask8.mp4"
      @click="playOrPause()"
    ></video>

    <!-- 
      The following div elements are conditionally displayed using the v-if directive 
      based on the value of videoState.
     -->

    <!-- Displayed when the videoState is true (showing the pause button)  -->
    <div v-if="videoState" class="play-pause-container fade-in-anim">
      <img
        class="play-pause-icon"
        @click="playOrPause()"
        src="@/assets/img/pause-button.svg"
        alt="play-button"
      />
    </div>

    <!-- Displayed when the videoState is false (showing the play button and a text caption) -->
    <div v-if="!videoState" class="play-pause-container fade-in">
      <div class="play-pause-icon-container">
        <img
          class="play-pause-icon"
          @click="playOrPause()"
          src="@/assets/img/play-button.svg"
          alt="pause-button"
        />
      </div>

      <div class="play-pause-text-container">
        <h2 class="play-pause-text">
          Founder and Aboriginal <br />
          Artist John Smith Gumbula <br />
          explains IndigeNFT
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurVideo",

  /**@data 
    Two data properties: 'videoState' and 'controls'. 
     @description
    'videoState' is initially set to false and controls the state of the video 
    - when it's true the video is playing, and when it's false the video is paused. 

    'controls' is initially set to true and controls whether the video player controls are displayed. 
  */
  data() {
    return {
      videoState: false,
      controls: true,
    };
  },
  methods: {
    /** @method
     * 'playOrPause' method which toggles the videoState property
     *  between true and false and plays or pauses the video accordingly.
     */
    playOrPause() {
      /* Get a reference to the video element */
      const video = document.getElementsByClassName("home-video")[0];

      /* When the videoState is true, it calls the play method on the video element, 
        and when it's false, it calls the pause method.*/
      this.videoState = !this.videoState;

      /* The setAttribute and removeAttribute methods show or hide the video player controls */
      if (this.videoState) {
        video.setAttribute("controls", "controls");
        video.play();
      } else {
        if (video.hasAttribute("controls"))
          video.removeAttribute("controls", "controls");
        video.pause();
      }
    },
  },
};
</script>
