<!-- 
  This template creates a responsive Navbar that displays in the different website pages.
  When viewed on a mobile, the navbar items are displayed in a Menu.
-->
<template>
  <div class="navbar-container">
    <!-- Project's Logo displayed on the desktop version -->
    <NavBarLogoVue />

    <div v-show="!mobile" style="display: contents">
      <div class="nav-collection">
        <!-- 
          Router to navigate to the collection page, when clicked the 'active-link' class 
          is added to the element, this class makes the label have an underline.      
        -->
        <router-link
          :class="{
            'nav-item': true,
            'active-link': $route.path === '/collection',
          }"
          to="/collection"
        >
          Collection
        </router-link>
      </div>

      <div class="navbar-menu">
        <!-- 
          The "v-for" directive is used to loop through the "socialInfo" 
          array and create an icon of each social media with their info
          attached. 
        -->
        <a
          v-for="(social, index) in socialInfo"
          :key="index"
          :href="social.href"
          target="_blank"
        >
          <!-- The "require" function is used to load the social 
          image file dynamically.  -->
          <img
            :src="require(`@/assets/img/icons/${social.image}`)"
            :alt="social.alt"
          />
        </a>
        <a class="white-button" href="/#contact">Get in touch</a>
      </div>
    </div>

    <!-- ============================================================== -->

    <div class="icon">
      <!-- Creates an icon which toggles the mobile navigation menu on and off when clicked -->
      <img
        @click="toggleMobile"
        :src="
          mobile && !mobileNav
            ? require('@/assets/img/icons/atom-icon-menu-dark.svg')
            : require('@/assets/img/icons/atom-icon-close-dark.svg')
        "
        :class="{ 'icon-active': mobileNav }"
        alt=""
        v-if="mobile || mobileNav"
      />
    </div>

    <Transition class="mobile-container">
      <div v-if="toggleMobileNav" class="dropdownNavs">
        <!-- Project's Logo displayed on the mobile version -->
        <div class="logos-mobile">
          <!-- When clicked, the Menu is closed through the 'toggleMobile' method -->
          <NavBarLogoVue @click="toggleMobile()" />
        </div>
        <div class="mobile-links">
          <router-link to="/collection" @click="toggleMobile()">
            Collection
          </router-link>
          <a href="/#roadmap" @click="toggleMobile()">Our Mission</a>
          <a href="/#about" @click="toggleMobile()">About Indige</a>
          <button class="white-button" @click="toggleMobile()">
            <a href="/#contact">Get in touch</a>
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import NavBarLogoVue from "./NavBarLogo.vue";

export default {
  name: "Main-navbar",
  components: {
    NavBarLogoVue,
  },
  data() {
    return {
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      toggleMobileNav: false,
      /* 
        The array of objects called 'socialInfo' stores the social's link to it,
        the social image and alt text. And the social's information is iterated 
        over using the v-for directive to display them on the page.
      */
      socialInfo: [
        {
          href: "https://www.instagram.com/indigenft",
          image: "atom-icon-instagram-dark.svg",
          alt: "Instagram",
        },
        {
          href: "https://discord.com/invite/rUkaqDNBTh",
          image: "atom-icon-discord-dark.svg",
          alt: "Discord",
        },
        {
          href: "https://twitter.com/IndigeNft",
          image: "atom-icon-twitter-dark.svg",
          alt: "Twitter",
        },
      ],
    };
  },
  /**@event
   * Event listener for the resize event on the window object, which triggers the checkScreen method
   */
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },

  methods: {
    /* Sets the mobile navigation Menu on and off */
    toggleMobile() {
      this.toggleMobileNav = !this.toggleMobileNav;
      this.mobileNav = !this.mobileNav;
    },
    /**@method
     * This method updates the mobile property based on the screen width,
     * and sets it to true if the screen width is less than or equal to 1300 pixels.
     */
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.mobile = this.windowWidth <= 940;

      if (this.windowWidth >= 940) {
        this.toggleMobileNav = false;
        this.mobileNav = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-container {
  max-width: 1250px;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 25px 35px 0;
}

.navbar-menu {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 15px;
    cursor: pointer;
  }
}

.nav-collection {
  margin-right: auto;

  .nav-item {
    text-decoration: none;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #fff;
    text-transform: uppercase;
    opacity: 1;
    margin: 0 30px;
  }
  .nav-item:hover {
    text-decoration: none;
  }

  .active-link {
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 3px;
  }

  a:hover {
    text-decoration: underline;
  }
}

/* ======================================================================================= */
/* MOBILE BELOW */
.icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 38px;
  z-index: 2;
  img {
    height: 35px;
    width: 35px;
  }

  &.icon-active {
    transform: rotate(180deg);
  }
}
.mobile-container {
  background: #01121e;
  position: absolute;
  padding-top: 25px;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1;
}
.dropdownNavs {
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 12px 12px;
  box-shadow: 2px 2px 10px 5px rgba(255, 255, 255, 0.2);
  a {
    text-decoration: none;
    color: #fff;
    padding: 20px 0;
  }

  .white-button a {
    color: black;
  }
}

.mobile-links {
  display: flex;
  flex-direction: column;
}

.logos-mobile {
  cursor: pointer;
  align-self: flex-start;
  padding: 0 35px 0;
  transform: translateY(-10%);

  img:first-of-type {
    max-width: 55px;
    height: 50px;
  }

  img:nth-of-type(2) {
    max-width: 145px;
    height: 36px;
    margin-right: 1px;
  }
}

.navbar-container .white-button {
  max-width: 180px;
  padding: 12px 30px;
  margin-left: 10px;
  border-radius: 8px;
  align-self: center;
  text-transform: uppercase;
  font-size: 14px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
