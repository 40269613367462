<!-- 
 This template creates a responsive "Contactform".  It includes a section with a 
 form for users to contact the website's team.
-->
<template>
  <section class="grid-center">
    <!-- 
      If the user clicks on the Get in Touch button, the page scrolls 
      down to the make a difference page. That's why the section is 
      identified with an ID    
    -->
    <div class="contact-section" id="contact">
      <h2>Contact us</h2>
      <p class="contact-section-info">
        If you would like to know more about us or get in touch with a member of
        the team, please use the form below and we will get back to you as soon
        as possible.
      </p>

      <!-- 
        When the form is submitted, it sends a POST request to "https://formsubmit
        .co/indigenft@risidio.com" using a third party service.
       -->
      <form
        class="contact-form"
        action="https://formsubmit.co/indigenft@risidio.com"
        method="POST"
      >
        <div class="info-container">
          <!-- 
            When the email is sent, "captcha" is disabled
            Hidden input field "_captcha" with a value of "false"
          -->
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="_cc" value="buranaustralia@gmail.com" />

          <input
            type="text"
            id="f-name"
            placeholder="Name"
            name="Name"
            required
          />
          <input
            type="email"
            id="f-email"
            placeholder="Email"
            name="Email"
            required
          />
        </div>
        <div class="subject-container">
          <input
            type="text"
            placeholder="Subject"
            id="f-subject"
            name="Subject"
            required
          />
        </div>
        <div class="f-area">
          <textarea
            placeholder="Your message"
            id="f-message"
            name="Message"
            style="resize: none"
            required
          ></textarea>
        </div>
        <button type="submit" class="blue-button">
          Submit
          <img src="@/assets/img/arrowup.png" alt="" class="arrow" />
        </button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contactform",
};
</script>
