<!-- 
  Simple responsive Make a Difference component that invites the user to join the
  discord if he wants to get on the WhiteList
-->
<template>
  <!-- 
    If the user clicks on the banner button 'Get on the WhiteList', the page
    scrolls down to the make a difference page. That's why the section
    is identified with an ID    
  -->
  <section class="makeDifference" id="difference">
    <div class="makeDifference-container">
      <h2>Want to make a difference?</h2>
      <p>Support us in preserving and promoting indigenous art and culture.</p>
      <a
        href="https://discord.com/invite/rUkaqDNBTh"
        target="_blank"
        class="white-button"
        >Get on the Whitelist</a
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "Makedifference",
};
</script>
