<!-- 
    This is a child of the AboutIndige component and it's rendered depending 
    the mobile data property of the parent. 
    Making it a separate component optimizes the code and avoids repetitions.
 -->
<template>
  <div class="about-container-image">
    <img src="@/assets/img/indige-41.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: "AboutIndigeImage",
};
</script>
