<!-- 
  This template creates a responsive About Indige section that displays different content depending on 
  the device it's viewed on. 
 -->
<template>
  <section class="roadmap" id="roadmap">
    <div class="roadmap-container">
      <div class="roadmap-container-content">
        <div class="roadmap-container-content-text">
          <!-- 
            'getRoadmapData' retrieves specific pieces of data from the 
            homeData prop (which comes from prismic) 
          -->
          <h2>{{ getRoadmapData("subtitle") }}</h2>
          <p class="headline-tag">
            {{ getRoadmapData("title") }}
          </p>

          <!-- If 'mobile' data property is triggered, Roadmap child component 
            is rendered based on the mobile version -->
          <Roadmap_Gif v-show="mobile" />

          <!-- Brief summary of IndigeNFT's objectives and what owning an IndigeNFT will provide. -->
          <p>
            IndigeNFT roadmap is for Stacks, Bitcoin and Ethereum holders.
            <br /><br />
            Owning an IndigeNFT from the Genesis Collection will provide the
            investor with an IndigeNFT VIP whitelist for future collections.
            <br /><br />
            Our objective is to preserve indigenous culture and educate global
            communities and collectors about the diverse artistic expressions,
            cultural heritage and language. IndigeNFT will connect indigenous
            communities utilising Web3 and emerging and future technologies.
          </p>
        </div>

        <!-- Roadmap child component rendered if It's the Desktop version -->
        <Roadmap_Gif v-show="!mobile" />
      </div>

      <div class="card-container">
        <!-- 
          The "v-for" directive is used to loop through the "roadmapCards" 
          array and create a section for each card. The "require" function 
          is used to load the card image file dynamically. 
        -->
        <section
          v-for="(card, index) in roadmapCards"
          :key="index"
          class="card"
        >
          <img
            :src="require(`@/assets/img/${card.image}`)"
            alt="placeholder"
            class="card-image"
          />
          <h3 class="card-title">{{ getRoadmapData(card.title) }}</h3>
          <p class="card-text">{{ getRoadmapData(card.text) }}</p>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import Roadmap_Gif from "../Roadmap/Roadmap-Gif.vue";

export default {
  name: "roadmap",

  /* The Roadmap component receives a prop called homeData,
   which is an object that contains data needed for the text. */
  props: ["homeData"],

  /**
   *@description
      Register the Roadmap_Gif component
    @summary
      The component uses a child component called Roadmap_Gif,
      which is conditionally rendered based on the mobile data property.
   */
  components: {
    Roadmap_Gif,
  },
  data() {
    return {
      windowWidth: null,
      mobile: false,
      /* 
        The array of objects called 'roadmapCards' stores the card's images,
        titles and text. And the card information is iterated over using 
        the v-for directive to display them on the page.
      */
      roadmapCards: [
        {
          image: "NFT-collections.png",
          title: "rmtitle1",
          text: "rmtext1",
        },
        {
          image: "indige-marketplace.png",
          title: "rmtitle2",
          text: "rmtext2",
        },
        {
          image: "Indige-metaverse.png",
          title: "rmtitle3",
          text: "rmtext3",
        },
      ],
    };
  },
  /**@event
   * Event listener for the resize event on the window object, which triggers the checkScreen method
   */
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    /**@method
     * This method updates the mobile property based on the screen width,
     * and sets it to true if the screen width is less than or equal to 1300 pixels.
     */
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.mobile = this.windowWidth <= 1300;
    },
    /**@method
     * The 'getRoadmapData' method is used to retrieve specific pieces of data from the homeData object.
     * @summary
     * It takes a key as an argument and returns the text associated with that key.
     * If the text is not available, it returns an empty string.
     * */
    getRoadmapData(key) {
      return this.homeData?.data?.roadmap[0]?.[key]?.[0]?.text || "";
    },
  },
};
</script>
