<!-- 
    This is a child of the HomeBanner component and it's rendered depending 
    the mobile data property of the parent. 
    Making it a separate component optimizes the code and avoids repetitions.
 -->
<template>
  <div class="banner-container-card">
    <img
      class="banner-container-card-cover"
      src="@/assets/img/nfts-alot.jpeg"
      alt=""
    />
    <p class="banner-container-card-name">IndigeNFT Collection</p>
  </div>
</template>

<script>
export default {
  name: "Homebanner-Card",
};
</script>
