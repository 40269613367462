<template>
  <div id="app">
    <header>
      <MainNavbar />
    </header>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import MainNavbar from "./components/layout/MainNavbar.vue";

export default {
  name: "App",
  components: {
    MainNavbar,
  },
};
</script>
