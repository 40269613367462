<!-- 
    This is a child of the Roadmap component and it's rendered depending 
    the mobile data property of the parent. 
    Making it a separate component optimizes the code and avoids repetitions.
 -->
<template>
  <router-link to="/" class="logo-container">
    <img src="@/assets/img/indigelogo-hand.png" />
    <img src="@/assets/img/Indigewhite2.png" alt="Indige" />
  </router-link>
</template>

<script>
export default {
  name: "NavBarLogo",
};
</script>

<style lang="scss">
.logo-container {
  cursor: pointer;
  transform: translateY(-10%);

  img:first-of-type {
    max-width: 55px;
    height: 50px;
  }

  img:nth-of-type(2) {
    max-width: 145px;
    height: 36px;
    margin-right: 1px;
  }
}
</style>
