<!-- 
  This template creates a responsive banner that displays different content depending on 
  the device it's viewed on. 
  It also uses the Vue.js router to navigate to different pages within the app. 
-->
<template>
  <div class="banner">
    <section style="margin-right: 5%; margin-left: 5%">
      <div class="banner-container">
        <div class="banner-container-items">
          <!-- 'getBannerData' retrieves specific pieces of data from the homeData prop (which comes from prismic) -->
          <h2>{{ getBannerData("slogan", 0) }}</h2>

          <p class="headline-tag">
            <i>{{ getBannerData("title", 0) }}</i>
          </p>

          <!-- If 'mobile' data property is triggered, BannerCard child component 
            rendered based on the mobile version -->
          <BannerCard v-show="mobile" />

          <p>
            {{ getBannerData("bannertext", 0) }}
            <br /><br />
            {{ getBannerData("bannertext", 1) }}
          </p>

          <!-- The banner-container-buttons element contains two router-link components 
              that are used to navigate to different pages within the app. -->
          <div class="banner-container-buttons">
            <!-- The first link navigates to the /collection page -->
            <router-link to="/collection" class="white-button"
              >Preview Collection
            </router-link>

            <!-- Second link navigates to the /difference page. The second link also contains an image element to display an arrow icon. -->
            <a href="#difference" class="blue-button"
              >Get on the Whitelist <img src="@/assets/img/arrowup.png" alt=""
            /></a>
          </div>
        </div>

        <!-- BannerCard child component rendered based on the Desktop version -->
        <BannerCard v-show="!mobile" />
      </div>
    </section>
  </div>
</template>

<script>
import BannerCard from "../Banner/Homebanner-Card.vue";

export default {
  name: "Homebanner",

  /* The Homebanner component receives a prop called homeData,
   which is an object that contains data for the banner. */
  props: ["homeData"],

  /**
   *@description
      Register the BannerCard component
    @summary
      The component uses a child component called BannerCard,
      which is conditionally rendered based on the mobile data property.
   */
  components: {
    BannerCard,
  },
  data() {
    return {
      mobile: null,
      windowWidth: null,
    };
  },
  /**@event
   * Event listener for the resize event on the window object, which triggers the checkScreen method
   */
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    /**@method
     * The 'getBannerData' method is used to retrieve specific pieces of data from the homeData object.
     * @summary
     * It takes a key and an index as arguments and returns the text associated with that key and index.
     * If the text is not available, it returns an empty string.
     * */
    getBannerData(key, index) {
      return this.homeData?.data?.homebanner[0]?.[key]?.[index]?.text || "";
    },

    /**@method
     * This method updates the mobile property based on the screen width,
     * and sets it to true if the screen width is less than or equal to 1050 pixels.
     */
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.mobile = this.windowWidth <= 1050;
    },
  },
};
</script>
