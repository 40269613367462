<!-- This is a child component of the "Nfts" Page which is the Collection Page -->
<template>
  <div class="collection-container">
    <!-- 
      Inside of the 'collection-info', there is an h3 element displaying the 
      title of the currently selected collection and a p element displaying 
      the text of that collection, which is a list of strings. 
    -->
    <div class="collection-info">
      <h3 style="max-width: 400px; margin: auto">
        {{ currentCollection.title }}
      </h3>
      <br />
      <p v-for="(data, index) of currentCollection.text" :key="index">
        {{ data.text }}<br /><br />
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "collectionText",
  /** @prop
   * The component has two props, tab and homeData, which are used in a computed property
   * named "currentCollection". "currentCollection" returns an object with a title and text
   * property based on the selected tab and the homeData prop.
   * */
  props: ["tab", "homeData"],

  /**@computed
   * The switch statement inside "currentCollection" checks the value of the tab prop and assigns
   * the appropriate title and text from the homeData object to the currentCollection object.
   * */
  computed: {
    currentCollection() {
      const collectionText = this.homeData?.data?.collectiontext[0];
      switch (this.tab) {
        case "Kanga":
          return {
            title: collectionText?.kangatitle[0]?.text || "",
            text: collectionText?.kangatext || [],
          };
        case "Owl":
          return {
            title: collectionText?.owltitle[0]?.text || "",
            text: collectionText?.owltext || [],
          };
        case "Wombat":
          return {
            title: collectionText?.wombattitle[0]?.text || "",
            text: collectionText?.wombattext || [],
          };
        case "Koala":
          return {
            title: collectionText?.koalatitle[0]?.text || "",
            text: collectionText?.koalatext || [],
          };
        case "Celebrity":
          return {
            title: collectionText?.celebritytitle[0]?.text || "",
            text: collectionText?.celebritytext || [],
          };
        default:
          return { title: "", text: [] };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collection-container {
  max-width: 1125px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 20px;
  gap: 50px;

  & .collection-info {
    flex: 1 1 400px;

    p {
      max-width: 850px;
      margin: auto;
      padding: 0 10px;
    }
  }
}
</style>
