<!-- 
    The following component called "Featuredwork" contains a section that 
    displays a list of NFTs (Non-Fungible Tokens), with each NFT displayed 
    as a card with an image and a name.
-->
<template>
  <div class="FeaturedWork">
    <section class="FeaturedWork-container">
      <div class="FeaturedWork-container-top">
        <h2>Featured Work</h2>
        <!-- Child component rendered if It's the Desktop version -->
        <Featuredwork_More_button v-show="!mobile" />
      </div>

      <!-- The "FeaturedWork-container-content" div contains a 
        loop that iterates over the "generateNFT" computed property, 
        displaying each NFT as a card.  -->
      <div class="FeaturedWork-container-content">
        <!-- 
        The generateNFT array is looped through, and for each item, 
        a div with a class of nft-card is created, which contains 
        an img tag and a p tag to display the NFT name.
       -->
        <div
          v-for="(data, index) of generateNFT"
          :key="index"
          class="nfts-container"
        >
          <div class="nft-card">
            <img
              class="nft-cover"
              :src="require(`@/assets/img/indige_images/${data.image}.png`)"
              alt=""
            />
            <p class="nft-name">{{ data.name }}</p>
          </div>
        </div>
      </div>

      <!-- 
        If 'mobile' data property is triggered, child component 
        is rendered based on the mobile version 
      -->
      <div class="FeaturedWork-container-bottom">
        <Featuredwork_More_button v-show="mobile" />
      </div>
    </section>
  </div>
</template>

<script>
import Featuredwork_More_button from "../FeaturedWork/Featuredwork-More-button.vue";

export default {
  name: "Featuredwork",

  /**
   *@description
      Register the Featuredwork_More_button component
    @summary
      The component uses a child component called 'Featuredwork_More_button'
      that is conditionally rendered depending on the screen size..
   */
  components: {
    Featuredwork_More_button,
  },
  data() {
    return {
      windowWidth: null,
      mobile: false,
    };
  },

  /**@event
   * Event listener for the resize event on the window object, which triggers the checkScreen method
   */
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },

  /**@method
   * This method updates the mobile property based on the screen width,
   * and sets it to true if the screen width is less than or equal to 960 pixels.
   */
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.mobile = this.windowWidth <= 1050;
    },
  },

  /**@property
   * The "computed" property "generateNFT" returns an array of objects
   * representing the NFTs to display, including their names, images, and types.
   */
  computed: {
    generateNFT() {
      return [
        { name: "Elton John", image: "Elton_John", type: "Celebrity" },
        { name: "John Lennon", image: "John_Lennon", type: "Celebrity" },
        { name: "Patty Mills", image: "New_Patty_Mills", type: "Celebrity" },
        { name: "Prince", image: "Prince", type: "Celebrity" },
      ];
    },
  },
};
</script>
