import { createPrismic } from "@prismicio/vue";

// import { linkResolver } from "./linkResolver";

const prismic = createPrismic({
  endpoint: "https://indige.prismic.io/api/v2",
  clientConfig: { defaultParams: { routes: [{ type: 'indige-home', path: '/'}]}}
  // linkResolver,
});

export default prismic;