<!-- 
    This is a child of the Featured component and it's rendered depending 
    the mobile data property of the parent. 
    Making it a separate component optimizes the code and avoids repetitions.
 -->
<template>
  <router-link class="blue-button" to="/collection">See more</router-link>
</template>

<script>
export default {
  name: "Featuredwork-More-button",
};
</script>
