<!-- 
  This template section displays the Layout of the Homepage of the Indige website.
  The component includes a div with an id of "Homepage" and several child components
  including a banner, information about the company, featured work, a video, a roadmap,
  a quote, information about how to make a difference, a contact form, and a footer.
-->
<template>
  <div>
    <div id="Homepage">
      <Homebanner v-bind:homeData="homeData" />
      <AboutIndige v-bind:homeData="homeData" />
      <Featuredwork />
      <OurVideo v-bind:homeData="homeData" />
      <Roadmap v-bind:homeData="homeData" />
      <Quote v-bind:homeData="homeData" />
      <Makedifference v-bind:homeData="homeData" />
      <Contactform />
      <Footer />
    </div>
  </div>
</template>

<script>
import Homebanner from "../components/homepage/Banner/Homebanner.vue";
import AboutIndige from "../components/homepage/AboutIndige/AboutIndige.vue";
import Featuredwork from "../components/homepage/FeaturedWork/Featuredwork.vue";
import OurVideo from "../components/homepage/Video/OurVideo.vue";
import Roadmap from "../components/homepage/Roadmap/Roadmap.vue";
import Quote from "../components/homepage/Quote/Quote.vue";
import Makedifference from "../components/homepage/MakeDifference/Makedifference.vue";
import Contactform from "../components/homepage/ContactForm/Contactform.vue";
import Footer from "../components/layout/Footer.vue";

import { useSinglePrismicDocument } from "@prismicio/vue";

export default {
  name: "Homepage",
  components: {
    Homebanner,
    AboutIndige,
    Featuredwork,
    OurVideo,
    Roadmap,
    Quote,
    Makedifference,
    Contactform,
    Footer,
  },
  data() {
    return {
      homeData: [],
    };
  },
  /**@mounted
   *  The mounted lifecycle hook calls the getPrismic method
   *  to fetch data and populate the homeData array with the fetched data.
   */
  mounted() {
    this.getPrismic();
  },
  /**@method
   * Single method called getPrismic that uses the useSinglePrismicDocument function
   * to fetch data from the CMS and sets the homeData array to the fetched data.
   *  */
  methods: {
    getPrismic() {
      const { data } = useSinglePrismicDocument("indige-home");
      this.homeData = data;
    },
  },
};
</script>

<style scoped>
#Homepage {
  background: #01121e;
  overflow-x: hidden;
}
</style>
