<!--  
  This is a Vue.js component that displays the collection of the Indige NFTs (non-fungible tokens) 
-->
<template>
  <div class="collection">
    <div class="collection-searchBar-container">
      <!-- 
        The search bar at the top that allows the user to filter the 
        results by animal type (e.g. owl, kanga, wombat, koala, etc.). 
      -->
      <div class="searchBar" style="padding-top: 5%">
        <p
          v-for="item in menuItems"
          :key="item.id"
          :id="item.id"
          :class="item.id === 'all' ? 'active-link' : ''"
          @click="tabChange(item.id)"
        >
          {{ item.name }}
        </p>
      </div>
    </div>

    <!-- ======================================================================================================= -->
    <div class="collection-tab-data-container">
      <section>
        <!-- If the "all" tab is selected, get the title and text from pismic -->
        <div v-if="tab === 'all'">
          <h3>
            {{ getCollectionData("alltitle") }}
          </h3>
          <br />
          <p class="tab-Text">
            {{ getCollectionData("alltext") }}
          </p>
        </div>

        <!-- Get the title and text from a specific animal when a specific tab is selected -->
        <div v-else>
          <CollectionText
            v-bind:tab="tab"
            v-bind:animal="animal"
            v-bind:homeData="homeData"
          />
        </div>

        <div class="collection-cards-container">
          <!-- Iterate over an array of objects named filteredNFTs.  -->
          <div
            v-for="(data, index) of filteredNFTs"
            :key="index"
            class="nfts-container"
          >
            <!-- 
              For each object in the array, a div is created containing the class="nft-card". 
              Inside the nft-card div, there is an img element that displays an image of the 
              item and a p element that displays the name of the item. 
            -->
            <div class="nft-card">
              <img
                class="nft-cover"
                :src="require(`@/assets/img/indige_images/${data.image}.png`)"
                alt=""
              />
              <p class="nft-name">{{ data.name }}</p>
            </div>
          </div>
        </div>
      </section>
      <!-- ============================================================================= -->
      <!-- The button calls the 'moveUp' method that scrols to the top of the page -->
      <router-link to @click="moveUp"
        ><button class="blue-button">
          Back to top <img src="../assets/img/arrowdown.png" alt="" /></button
      ></router-link>
    </div>
  </div>
</template>

<script>
import CollectionText from "../components/snippets/collectionText.vue";
import { useSinglePrismicDocument } from "@prismicio/vue";

export default {
  name: "Nfts",
  /**@components
   * CollectionText is used to display a section of text about the NFT collection
   * */
  components: {
    CollectionText,
  },
  data() {
    return {
      homeData: [],
      tab: "all",
      /* Used on top bar */
      menuItems: [
        { id: "all", name: "All" },
        { id: "Owl", name: "Owl" },
        { id: "Kanga", name: "Kanga" },
        { id: "Wombat", name: "Wombat" },
        { id: "Koala", name: "Koala" },
        { id: "Celebrity", name: "Celebrity" },
      ],
    };
  },
  /**@mounted
   * The mounted lifecycle hook is used to call moveUp (which scrolls the page to the top)
   * and getPrismic (which retrieves some data from Prismic).
   */
  async mounted() {
    this.moveUp();
    this.getPrismic();
  },
  methods: {
    getCollectionData(key) {
      return this.homeData?.data?.collectiontext[0]?.[key]?.[0]?.text || "";
    },
    /* "getPrismic" uses the "useSinglePrismicDocument" function to retrieve 
      data from Prismic and store it in the homeData property */
    getPrismic() {
      const { data } = useSinglePrismicDocument("indige-home");
      this.homeData = data;
    },
    /* MoveUp scrolls the page to the top */
    moveUp() {
      window.scrollTo(0, 0);
    },
    /* 
      "tabChange" is called when the user clicks on one of the tabs in the search bar. 
      It sets the tab property to the selected tab, updates the CSS class of the selected
      tab to "active-link", and filters the NFTs based on the selected tab. 
    */
    tabChange(tab) {
      this.tab = tab;
      const elementIds = [
        "all",
        "Owl",
        "Kanga",
        "Wombat",
        "Koala",
        "Celebrity",
      ];
      elementIds.forEach((id) => {
        document.getElementById(id).classList.remove("active-link");
      });
      document.getElementById(`${tab}`).classList.add("active-link");
      const filteredNFTs = this.generateNFT.filter((nft) =>
        nft.type.includes(this.tab)
      );
      this.animal = filteredNFTs;
    },
  },
  computed: {
    /* "filteredNFTs" returns an array of NFTs that match the currently selected tab. 
      If the "All" tab is selected, it returns all of the NFTs.  */
    filteredNFTs() {
      if (this.tab === "all") {
        return this.generateNFT;
      } else {
        return this.generateNFT.filter((nft) => nft.type.includes(this.tab));
      }
    },
    /* "generateNFT" returns an array of NFT objects, which includes the name of the NFT, 
    its image, and its animal type. */
    generateNFT() {
      const nftTypes = [
        { name: "Kanga", imageStartIndex: 5 },
        { name: "Owl", imageStartIndex: 20 },
        { name: "Wombat", imageStartIndex: 34 },
        { name: "Koala", imageStartIndex: 46 },
      ];
      const NFTs = [];
      NFTs.push(
        { name: "Elton John", image: "Elton_John", type: "Celebrity" },
        { name: "John Lennon", image: "John_Lennon", type: "Celebrity" },
        { name: "Patty Mills", image: "New_Patty_Mills", type: "Celebrity" },
        { name: "Prince", image: "Prince", type: "Celebrity" }
      );
      for (const nftType of nftTypes) {
        for (let x = 0; x < 12; ++x) {
          const nftObject = {
            name: `Indige ${nftType.name}`,

            image: nftType.imageStartIndex + x,
            type: nftType.name,
          };
          NFTs.push(nftObject);
        }
      }
      return NFTs;
    },
  },
};
</script>
