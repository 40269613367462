<!-- 
    This is a child of the Quote component and it's rendered depending 
    the mobile data property of the parent. 
    Making it a separate component optimizes the code and avoids repetitions.
 -->
<template>
  <div class="artist-image-container">
    <!-- Display John's image-->
    <img class="john" :src="johnImage" alt="John Smith Gumbula" />
    <p class="artist-image-container-info">
      IndigeNFT Founder, Cultural Ambassador and WakkaWakka - GurangGurang
      Artist
    </p>

    <!-- 
        The "v-for" directive is used to loop through the "socials" 
        array and create an icon for every social media with It's 
        name and reference.
    -->
    <a
      v-for="social in socials"
      :key="social.name"
      :href="social.link"
      target="_blank"
    >
      <img class="icon" :src="social.icon" :alt="social.name" />
    </a>
  </div>
</template>

<script>
export default {
  name: "Quote-Artist",
  computed: {
    johnImage() {
      return require("@/assets/img/JohnImage.png");
    },
    /* 
        The array of objects called 'socials' stores all of John's 
        social media information. And this social information is 
        iterated over using the v-for directive to display it
        on the page.
      */
    socials() {
      return [
        {
          name: "Instagram",
          icon: require("@/assets/img/icons/atom-icon-instagram-dark.svg"),
          link: "https://www.instagram.com/johnsmithgumbula/",
        },
        {
          name: "Twitter",
          icon: require("@/assets/img/icons/atom-icon-twitter-dark.svg"),
          link: "https://twitter.com/jsgumbula",
        },
      ];
    },
  },
};
</script>
