import { createApp } from "vue";
import App from "./App.vue";
import "../src/assets/styles/main.scss";

import router from "./router";
import prismic from "./prismic";
// import LoadScript from 'vue-plugin-load-script';

// var Vue = require('vue')
// Vue.use(LoadScript);
createApp(App).use(router).use(prismic).mount("#app");
