import { createRouter, createWebHistory } from "vue-router";

import Homepage from "./views/Homepage";
import Nfts from "./views/Nfts";

const routes = [
  {
    path: "/",
    name: "home",
    component: Homepage,
  },
  {
    path: "/collection",
    name: "collection",
    component: Nfts,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
