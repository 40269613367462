<!-- 
  This template creates a responsive Footer.
  When viewed on a mobile, the footer items change positions
-->
<template>
  <div class="separator-container" />
  <div class="footer">
    <div class="footer-container">
      <div class="footer-container-left-side">
        <!-- Show company's Logo with the link to the corporate website attached -->
        <a href="https://risidio.com/" target="_blank"
          ><p class="poweredby-label">Powered by</p>
          <img src="../../assets/img/Group18619.svg" alt="Risidio"
        /></a>
      </div>

      <div class="footer-container-socials-side">
        <p class="followUs-label">Follow Us</p>
        <!-- 
          The "v-for" directive is used to loop through the "socialInfo" 
          array and create an icon of each social media with their info
          attached. 
        -->
        <a
          v-for="(social, index) in socialInfo"
          :key="index"
          :href="social.href"
          target="_blank"
        >
          <!-- The "require" function is used to load the social 
          image file dynamically.  -->
          <img
            :src="require(`@/assets/img/icons/${social.image}`)"
            :alt="social.alt"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      /* 
        The array of objects called 'socialInfo' stores the social's link to it,
        the social image and alt text. And the social's information is iterated 
        over using the v-for directive to display them on the page.
      */
      socialInfo: [
        {
          href: "https://www.instagram.com/indigenft",
          image: "atom-icon-instagram-dark.svg",
          alt: "Instagram",
        },
        {
          href: "https://discord.com/invite/rUkaqDNBTh",
          image: "atom-icon-discord-dark.svg",
          alt: "Discord",
        },
        {
          href: "https://twitter.com/IndigeNft",
          image: "atom-icon-twitter-dark.svg",
          alt: "Twitter",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.separator-container {
  display: block;
  background-image: url("../../assets/img/NewHomeBackCompress.png");
  background-size: cover;
  padding: 5% 35px 0;
}
.footer {
  max-width: 1250px;
  margin: 0 auto;
  padding: 25px 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 35px 0;

  &-left-side {
    padding-bottom: 20px;
    margin-left: 1%;

    a {
      text-decoration: none;
    }

    .poweredby-label {
      font-weight: 400;
      font-size: 1.2em;
      margin: 1em 0;
      text-align: left;
      color: white;
    }

    img {
      width: 70px;
      height: 40px;
      display: block;
      margin-left: 120px;
      margin-top: -51px;
    }
  }

  &-socials-side {
    position: absolute;
    z-index: 10;
    right: 2%;

    .followUs-label {
      font-weight: 400;
      font-size: 1.2em;
      color: white;
      display: inline-block;
    }

    img {
      width: 30px;
      height: 30px;
      margin: -8px 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-container {
    display: inline-block;
  }

  .footer-container-left-side {
    margin-left: 0%;
    .poweredby-label {
      text-align: center;
    }

    img {
      display: inline-block;
      margin-left: 0px;
    }
  }

  .footer-container-socials-side {
    position: relative;
    right: 0px;

    .followUs-label {
      display: block;
    }
    img {
      margin-bottom: 5%;
    }
  }
}
</style>
