<!-- 
    This is a child of the Roadmap component and it's rendered depending 
    the mobile data property of the parent. 
    Making it a separate component optimizes the code and avoids repetitions.
 -->
<template>
  <div class="gif">
    <img src="@/assets/img/IngiverseGif.gif" alt="metaverse" loading="lazy" />
  </div>
</template>

<script>
export default {
  name: "RoadmapGif",
};
</script>
